<template>
  <div>
    <b-modal
      size="sm"
      id="printModal"
      title="訊息提示視窗"
      @close="$emit('closeModal')"
      v-if="message"
    >
      <div>{{ message.text }}</div>
      <template #modal-footer>
        <b-button
          variant="primary"
          v-if="message.method === 'back'"
          @click.stop="$bvModal.hide('printModal')"
          >取消</b-button
        >
        <b-button
          variant="danger"
          @click.stop="
            $emit('confirmEvent', {
              method: 'confirmMessage',
              modalName: 'printModal',
            })
          "
          >確定</b-button
        >
      </template>
    </b-modal>

    <b-modal
      id="equipment"
      :title="
        `${item.hasOwnProperty('index') ? '編輯' : '新增'}教學設備費採購項目`
      "
      @close="emptyObject"
    >
      <b-input-group
        v-for="(column, idx) in equipColumns"
        :key="column.key"
        :prepend="column.label"
        :class="{ 'mt-1': idx > 0 }"
      >
        <b-form-input
          :type="column.type"
          v-model="item[column.key]"
          max="999999"
        />
      </b-input-group>
      <template #modal-footer>
        <b-button variant="danger" @click.stop="$bvModal.hide('equipment')"
          >取消</b-button
        >
        <b-button
          variant="primary"
          @click.stop="
            $emit('confirmEvent', {
              method: 'confirmEquipment',
              modalName: 'equipment',
            })
          "
          >確認</b-button
        >
      </template>
    </b-modal>

    <b-modal
      id="form"
      :title="
        `${item.hasOwnProperty('index') ? '編輯' : '新增'}暑期課程學分費補助`
      "
      @close="emptyObject"
    >
      <b-input-group
        v-for="(column, idx) in formColumns"
        :key="column.key"
        :prepend="column.label"
        :class="{ 'mt-1': idx > 0 }"
      >
        <b-select
          v-model="item[column.key]"
          v-if="column.type === 'select'"
          :options="column.options"
        />
        <b-form-input
          v-else
          :type="column.type"
          v-model="item[column.key]"
          max="999999"
        />
      </b-input-group>
      <template #modal-footer>
        <b-button variant="danger" @click.stop="cancelModal('form')"
          >取消</b-button
        >
        <b-button
          variant="primary"
          @click.stop="
            $emit('confirmEvent', {
              method: 'confirmEquipment',
              modalName: 'form',
            })
          "
          >確認</b-button
        >
      </template>
    </b-modal>

    <b-modal
      id="customApiModal"
      size="lg"
      :title="customModal ? customModal.label : ''"
    >
      <div
        v-if="customModal"
        class="d-grid"
        :class="`grid-col-${customModal.columns.length}`"
        style="grid-gap: 1px;"
      >
        <div
          v-for="(col, hidx) in customModal.columns"
          :key="hidx"
          class="bg-secondary text-white text-center"
          v-text="col.th"
        />
        <template v-if="items.length > 0">
          <template v-for="(item, cidx) in items">
            <div
              v-for="(col, hidx) in customModal.columns"
              :key="hidx + '_' + cidx"
              class="text-center"
              :class="{ odd: cidx % 2 === 1 }"
            >
              {{ item[col.key] }}
            </div>
          </template>
        </template>
        <div
          v-else
          class="border-right border-left border-bottom rounded-bottom text-center"
          :class="`grid-col-span-${customModal.columns.length}`"
        >
          no data
        </div>
      </div>
      <template #modal-footer>
        <b-button variant="primary" @click.stop="cancelModal('customApiModal')"
          >取消</b-button
        >
      </template>
    </b-modal>
  </div>
</template>

<script>
import { getStudentNameList } from "@/service/api.js";
export default {
  props: {
    message: {
      type: Object,
    },
    customModal: {
      type: Object,
    },
  },
  data() {
    return {
      item: {},
      equipColumns: [
        { key: "name", label: "設備名稱", type: "text" },
        { key: "amount", label: "採購金額", type: "number" },
        { key: "feeSource", label: "經費來源", type: "text" },
        { key: "instruction", label: "用途說明", type: "text" },
      ],
      formColumns: [
        {
          key: "name",
          label: "姓名",
          type: "select",
          options: getStudentNameList(),
        },
        { key: "nationality", label: "籍屬", type: "text" },
        { key: "department", label: "系別", type: "text" },
        { key: "major", label: "修習科目", type: "text" },
        { key: "hours", label: "學分", type: "text" },
        { key: "costPerCredit", label: "每學分費用", type: "text" },
        { key: "grades", label: "成績", type: "text" },
        { key: "actualPayment", label: "實際支付費用", type: "number" },
        { key: "remarks", label: "備註", type: "number" },
      ],
      items: [],
    };
  },
  methods: {
    emptyObject() {
      this.item = {};
      this.$emit("emptyCustomModal");
    },
    cancelModal(key) {
      this.$bvModal.hide(key);
      this.emptyObject();
    },
  },
  watch: {
    customModal(value) {
      if (value) {
        value
          .getItems()
          .then((res) => {
            this.items = res.Items;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
};
</script>

<style>
.odd {
  background-color: #efefef;
}
</style>
