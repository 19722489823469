<template>
  <div>
    <div
      v-if="fields"
      class="d-grid print-fields"
      :style="`grid-template-columns: ${fieldWidth};`"
    >
      <div
        v-for="(field, idx) in displayFields"
        :key="idx + '_' + field.text"
        class="th-field"
        :style="field.style"
      >
        {{ field.text }}
        <div class="sub-text" v-if="field.sub" v-text="field.sub" />
      </div>

      <template v-if="items.length > 0">
        <template v-for="(item, idx) in items">
          <div
            v-for="key in fieldKeys"
            :key="key + '_' + idx"
            class="td-field"
            :class="{ 'is-even': idx % 2 === 1 }"
          >
            <template v-if="isCucaulations(key)">
              {{
                cucaulations[key].reduce((ac, cur) => ac + item[cur], 0)
                  | currency
              }}
            </template>
            <template v-else-if="isCurrency(key)">
              <template v-if="Array.isArray(item[key])">
                <div
                  v-for="(chItem, chIdx) in item[key]"
                  :key="chIdx"
                  class="w-100"
                  :class="{ 'border-top': chIdx > 0 }"
                >
                  {{ chItem | currency }}
                </div>
              </template>
              <template v-else>{{ item[key] | currency }}</template>
            </template>
            <vue-numeric
              v-else-if="isNumeric(key)"
              separator=","
              v-model="item[key]"
              class="w-100 text-right px-1"
            />
            <div v-else-if="key === 'operation'">
              <b-button
                variant="primary"
                v-if="item[key].edit"
                @click.stop="btnEvent(item[key].edit, item)"
                size="sm"
                class="mx-1"
                >編輯</b-button
              >
              <b-button
                variant="danger"
                v-if="item[key].delete"
                size="sm"
                class="mx-1"
                >刪除</b-button
              >
            </div>
            <div v-else-if="key === 'operations'" class="w-100 h-100 p-0">
              <div
                v-for="(major, midx) in item['major']"
                :key="'major' + midx"
                class="d-flex w-100 py-1 justify-content-center"
                :class="{ 'border-top': midx > 0 }"
              >
                <div
                  @click.stop="btnEvent(item[key].edit, { ...item, mid })"
                  size="sm"
                  class="mx-1 bg-primary text-white rounded small-button px-1"
                >
                  編輯
                </div>
                <div
                  size="sm"
                  class="mx-1 bg-danger text-white rounded small-button px-1"
                >
                  刪除
                </div>
              </div>
            </div>
            <template v-else>
              <template v-if="Array.isArray(item[key])">
                <div
                  v-for="(chItem, chIdx) in item[key]"
                  :key="chIdx"
                  class="w-100"
                  :class="{ 'border-top': chIdx > 0 }"
                >
                  {{ chItem }}
                </div>
              </template>
              <template v-else>{{ item[key] }}</template>
            </template>
          </div>
        </template>
      </template>
      <div v-else :style="`grid-column: 1 / ${fieldKeys.length} span`">
        NO DATA
      </div>
    </div>

    <!-- <div v-text="fieldLevels" />
    <div v-text="deepest" /> -->
    <!-- <div v-text="fieldKeys" /> -->
    <!-- <div v-text="chFields" /> -->
  </div>
</template>

<script>
export default {
  props: {
    fields: {
      type: Object,
      required: true,
    },
    items: {
      type: Array,
      default: () => [],
    },
    currencies: {
      type: Array,
      default: () => [],
    },
    numericies: {
      type: Array,
      default: () => [],
    },
    cucaulations: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    displayFields() {
      const fetchChildren = (value, acc) =>
        !value.items
          ? [acc]
          : Object.values(value.items).map((item) =>
              fetchChildren(
                item,
                acc + Object.values(value.items).filter((i) => !i.items).length
              )
            );
      const splitItem = (value, acc, level, offLeft) => {
        const children = !value.items
          ? 0
          : Math.max(
              ...fetchChildren(value, 0).flat(10),
              Object.values(value.items).length
            );
        const row = this.deepest - level + 1;
        const _newValue = {
          text: value.text,
          children,
          width: value.width,
          style: `${children === 0 && row > 1 ? "grid-row: span " + row : ""};${
            children ? "grid-column: span " + children : ""
          }`,
        };

        if (value.sub) _newValue["sub"] = value.sub;

        if (!acc[level]) {
          acc[level] = [_newValue];
        } else {
          acc[level].push(_newValue);
        }
        if (value.items) {
          // :style="{ gridRow: `1 / ${deepest - field.level}`, gridColumn: `${field.offLeft} / span ${field.items ? Object.values(field.items).length : 1}`}"
          Object.values(value.items).map((item) =>
            splitItem(item, acc, level + 1)
          );
        }
        return acc;
      };

      return Object.values(
        Object.values(this.fields).reduce((acc, item, offLeft) => {
          return splitItem(item, acc, 0, offLeft + 1);
        }, {})
      ).flat();
    },
    fieldLevels() {
      return Object.values(this.fields).map((field) =>
        this.checkItemsLevel(field, 0)
      );
    },
    fieldKeys() {
      const getChildKeys = ([key, value]) =>
        !value.items
          ? key
          : Object.entries(value.items).map((v) => getChildKeys(v));

      return Object.entries(this.fields)
        .reduce((keys, cur) => keys.concat(getChildKeys(cur)), [])
        .flat(10);
    },
    chFields() {
      return Object.values(this.fields).reduce(
        (list, field) => this.fetchChild(field, list),
        []
      );
    },
    fieldWidth() {
      return this.displayFields
        .filter((field) => field.children === 0)
        .reduce(
          (acc, field) =>
            acc.concat(
              !!field.width
                ? field.width
                : !!field.items
                ? Object.keys(field.items).map((v) => "1fr")
                : "1fr"
            ),
          []
        )
        .join(" ");
    },
    deepest() {
      return Math.max(...this.fieldLevels.flat(10));
    },
  },
  methods: {
    checkItemsLevel(value, total) {
      return !value.items
        ? total
        : Object.values(value.items).map((item) =>
            this.checkItemsLevel(item, total + 1)
          );
      // return Object.values(value).reduce((sum, item) => {

      //   // return !!item[items]
      //   //   ? this.checkItemsLevel(item.items, sum + 1)
      //   //   : sum
      //   return sum
      // }, total)
    },
    btnEvent(key, item) {
      // this.$bvModal.show(key);
      this.$emit("showModal", { modalName: key, item });
    },
    isNumeric(key) {
      return this.numericies.some((numeric) => numeric === key);
    },
    isCurrency(key) {
      return this.currencies.some((currency) => currency === key);
    },
    isCucaulations(key) {
      return this.cucaulations.hasOwnProperty(key);
    },
    dickNumber(obj, current) {
      if (obj && obj["items"] && Object.keys(obj["items"]).length > 0) {
        return Math.max(
          ...Object.values(obj["items"]).map((child) =>
            this.dickNumber(child, current + 1)
          )
        );
      } else {
        return current;
      }
    },
    fetchChild(obj, itemList) {
      if (obj && obj["items"] && Object.keys(obj["items"]).length > 0) {
        return itemList.concat(
          Object.values(obj["items"]).map((value) => value)
        );
      } else {
        return itemList;
      }
    },
  },
  created() {
    // let level = 1;
    // Object.values(this.fields).forEach((field,idx) => {
    //   const _columnNumber = field.items ? Object.keys(field.items).length : 0;
    //   const ocuppy = _columnNumber ? (_columnNumber) : 1;
    //   field.style = { gridRow: `1 / ${(this.deepest + 2) - (field.items ? 1 : 0)}`, gridColumn: `${level} / span ${ocuppy}` };
    //   level += ocuppy;
    // })
  },
  filters: {
    currency(value) {
      return Intl.NumberFormat("en-US").format(value);
    },
  },
};
</script>

<style lang="scss" scoped>
$lineColor: #e0e0e0;
.print-fields {
  border-right: 1px solid $lineColor;
  border-bottom: 1px solid $lineColor;
  > div {
    border-left: 1px solid $lineColor;
    border-top: 1px solid $lineColor;
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    &.th-field {
      background-color: #333;
      color: #fff;
    }
    &.td-field.is-even {
      background-color: #efefef;
    }
  }
}
.sub-text {
  width: 100%;
  font-size: 11px;
}
.small-button {
  font-size: 11px;
  cursor: pointer;
}
</style>
