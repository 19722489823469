<template>
  <div class="p-2">
    <h5 class="bg-primary text-white rounded-top p-2 mb-0" v-if="title">
      {{ title }}
    </h5>
    <div
      class="d-flex align-items-center border rounded-bottom mb-2 py-2 px-1"
      :class="{ 'rounded-top': !title }"
    >
      <calendarInput
        v-model="currentTime"
        v-if="btnItems && btnItems.includes('date')"
      />

      <b-button
        v-for="(cbitem, cidx) in customBtnItems"
        :key="cidx"
        variant="success"
        @click.stop="customBtnEvent(cbitem)"
        class="ml-1"
        >{{ cbitem.label }}</b-button
      >
      <b-button
        variant="light"
        v-if="btnItems && btnItems.includes('viewstudent')"
        @click.stop="viewStudentEvent"
        class="ml-1"
        >檢視公費生資訊</b-button
      >
      <b-button
        variant="info"
        v-if="btnItems && btnItems.includes('modify')"
        @click.stop="modifyEvent"
        class="ml-1"
        >修改</b-button
      >
      <b-button
        variant="info"
        v-if="btnItems && btnItems.includes('add')"
        @click.stop="addEvent"
        class="ml-1"
        >+新增</b-button
      >
      <b-button
        variant="warning"
        v-if="btnItems && btnItems.includes('save')"
        @click.stop="saveEvent"
        class="ml-1"
        >儲存</b-button
      >
      <b-button
        variant="primary"
        v-if="btnItems && btnItems.includes('return')"
        @click.stop="showBackModal"
        class="ml-1"
        >返回</b-button
      >
      <b-button
        variant="success"
        v-if="btnItems && btnItems.includes('print')"
        @click.stop="printEvent"
        class="ml-1"
        >列印</b-button
      >
    </div>
    <tableFields
      :fields="fieldItems"
      :items="items"
      :currencies="currencies"
      :numericies="numericies"
      :cucaulations="cucaulations"
      @showModal="showModal"
    />
    <modal
      ref="myModal"
      :message="message"
      :customModal="customModal"
      @closeModal="closeModalEvent"
      :editItem="editItem"
      @confirmEvent="confirmModalEvent"
      @emptyCustomModal="customModal = null"
    />
  </div>
</template>

<script>
import tableFields from "./tableFields";
import modal from "./modal";
import calendarInput from "@/components/localCalendar.vue";
export default {
  components: {
    tableFields,
    modal,
    calendarInput,
  },
  data() {
    return {
      fieldItems: null,
      items: [],
      currencies: [],
      numericies: [],
      message: null,
      returnMessage: "",
      currentTime: new Date(),
      btnItems: ["print"],
      processKey: "",
      editItem: null,
      fetchData: null,
      cucaulations: {},
      title: "",
      tabName: "",
      customBtnItems: [],
      customModal: null,
    };
  },
  created() {
    if (!this.$route.params.fieldItems) this.$router.go(-1);
    this.fieldItems = this.$route.params.fieldItems;
    if (this.$route.params.items) this.items = this.$route.params.items;
    if (this.$route.params.currencies)
      this.currencies = this.$route.params.currencies;
    if (this.$route.params.numericies) {
      this.numericies = this.$route.params.numericies;
    }
    if (this.$route.params.cucaulations)
      this.cucaulations = this.$route.params.cucaulations;
    if (this.$route.params.title) this.title = this.$route.params.title;
    if (this.$route.params.returnMessage)
      this.returnMessage = this.$route.params.returnMessage;
    if (this.$route.params.tabName) this.tabName = this.$route.params.tabName;
    if (this.$route.params.customBtnItems)
      this.customBtnItems = this.$route.params.customBtnItems;
    if (this.$route.params.btnItems)
      this.btnItems = this.$route.params.btnItems;
    this.processKey = this.$route.params.key;
    if (this.$route.params.getItems)
      this.fetchData = this.$route.params.getItems;
    this.getData();
  },
  methods: {
    customBtnEvent(item) {
      if (item.method === "callModalwithApi") {
        this.customModal = item;
        this.$nextTick(() => {
          this.$bvModal.show("customApiModal");
        });
      }
    },
    getData() {
      if (this.fetchData)
        this.fetchData()
          .then((res) => {
            this.items = res.Items;
          })
          .catch((err) => {
            console.log(err);
          });
    },
    addEvent() {
      this.$bvModal.show(this.processKey);
    },
    showModal({ modalName, item }) {
      if (modalName === "equipment" || modalName === "form") {
        this.$refs.myModal.item = JSON.parse(JSON.stringify(item));
        if (modalName === "form") {
          Object.keys(this.$refs.myModal.item).forEach((k) => {
            if (Array.isArray(this.$refs.myModal.item[k])) {
              this.$refs.myModal.item[k] = null;
            }
          });
        }
      }
      this.$nextTick(() => {
        this.$bvModal.show(modalName);
      });
    },
    showBackModal() {
      this.message = {
        text: this.returnMessage,
        method: "back",
      };
      this.$nextTick(() => {
        this.$bvModal.show("printModal");
      });
    },
    modifyEvent() {
      this.message = {
        text: "已修改",
        method: "save",
      };
      this.$nextTick(() => {
        this.$bvModal.show("printModal");
      });
    },
    viewStudentEvent() {
      this.message = {
        text: "公費生資訊",
        method: "save",
      };
      this.$nextTick(() => {
        this.$bvModal.show("printModal");
      });
    },
    saveEvent() {
      this.message = {
        text: "已成功儲存",
        method: "save",
      };
      this.$nextTick(() => {
        this.$bvModal.show("printModal");
      });
    },
    confirmModalEvent({ method, modalName }) {
      this.$bvModal.hide(modalName);
      if (method === "confirmMessage" && this.message.method === "back")
        this.$router.replace({
          name: "localbudgetmanagetab",
          params: { tabKey: this.tabName },
        });
      if (method === "confirmEquipment") {
        this.message = {
          text: "已成功儲存",
          method: "save",
        };
        setTimeout(() => {
          this.$bvModal.show("printModal");
        }, 1000);
      }
    },
    printEvent() {
      window.print();
    },
    closeModalEvent() {
      this.message = null;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep.btn-light {
  background: #7e2ca3;
  border-color: #7e2ca3;
  color: #fff;
}
</style>
