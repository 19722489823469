import { store } from "@/store/global.js";


const departmentList = [{value: '醫學系', text: '醫學系'}, {value: '牙醫系', text: '牙醫系'}, {value: '語治系', text: '語言治療學系'}];
const schoolList = ['0003', '0005', '0016', '0046', '0048', '0050', '0220', '0906', '1002', '1009', '1012', '1014', '1019', '1027', '1028', '1029', '1031', '1033', '1034', '1035', '1047', '1048', '1053', '1055', '1070', '1077', '1195'];
const deviceList = [
  { text: '書桌', value: 3000 },
  { text: '椅子', value: 1000 },
  { text: '電腦', value: 22000 }
];
const nationList = ['本島', '離島', '原住民', '外國'];
const nameList = ['林慧敏','阮友南','陳柏平','許玉婷','林柏茵','黃怡光','王志華','楊景禾','費大達','涂秋白','黃嘉政'];
const majoyList = [{text: "呼吸循環系統", value: "2.5", price: 1546},{text: "大體解剖實驗", value: "3", price: 2518},{text: "生殖內分泌系統", value: "2", price: 2994},{text: "組織學", value: "2", price: 2772},{text: "組織學實驗", value: "2", price: 1662},{text: "生物化學(一)", value: "2", price: 2185},{text: "有機化學 ", value: "2", price: 2154},{text: "微生物學", value: "2", price: 1657},{text: "寄生蟲學", value: "2", price: 1990},{text: "寄生蟲學", value: "2", price: 1674},{text: "生死學", value: "2", price: 2963},{text: "寄生蟲學實驗", value: "2", price: 2973},{text: "呼吸學", value: "2", price: 1532},{text: "內科實習", value: "2", price: 2786},{text: "血液及腫瘤學", value: "2", price: 1537},{text: "外科實習", value: "2", price: 1984},{text: "神經", value: "2", price: 1647},{text: "精神及麻醉學", value: "2", price: 1789},{text: "婦產科實習", value: "2", price: 2980},{text: "臨床神經學", value: "2", price: 2757},{text: "小兒科實習", value: "2", price: 2641},{text: "精神醫學", value: "2", price: 2033},{text: "急診醫學科實習", value: "2", price: 2213}];
const statusList = [{ value: 0, text: '預審中', message: 'please wait' }, { value: 1, text: '完成核定', message: '2021-06-28' }, { value: 2, text: '退回申請', message: 'you need to do some thing for ...' }];
const schoolObj = {
  '0003': "國立臺灣大學",
  '0005': "國立成功大學",
  '0016': "國立陽明交通大學",
  '0046': "國立臺北護理健康大學",
  '0048': "國立金門大學",
  '0050': "國立臺中科技大學",
  '0220': "臺中科技大學中護健康學院",
  '0906': "國防醫學院",
  '1002': "輔仁大學",
  '1009': "長庚大學",
  '1012': "大葉大學",
  '1014': "義守大學",
  '1019': "高雄醫學大學",
  '1027': "慈濟大學",
  '1028': "臺北醫學大學",
  '1029': "中山醫學大學",
  '1031': "輔英科技大學",
  '1033': "長榮大學",
  '1034': "弘光科技大學",
  '1035': "中國醫藥大學",
  '1047': "中臺科技大學",
  '1048': "亞洲大學",
  '1053': "元培醫事科技大學",
  '1055': "中華醫事科技大學",
  '1070': "長庚科技大學",
  '1077': "慈濟科技大學",
  '1195': "馬偕醫學大學"
}

const getDayStamp = num => num * 24 * 60 * 60 * 1000;
const getRandomDate = (period = 30) => new Date(Date.now() - getDayStamp(period) + Math.floor(Math.random() * getDayStamp(period)));
const getRandomSchool = () => schoolList[Math.floor(schoolList.length * Math.random())];
const getRandomYear = () => 100 + Math.floor(Math.random() * 11);
const getRandomNo = (num = 5) => (new Array(num).fill('0').join('') + Math.random() * Math.pow(10, num)).slice(-num);
const getRandomOption = (options, num = 1) => {
  const _items = new Array(num).fill('').reduce((ac, cu) => {
    const _restOfArr = options.filter(op => !ac.some(v => v === op));
    ac.push(_restOfArr[Math.floor(Math.random() * _restOfArr.length)]);
    return ac;
    }, []);
  return _items.length === 1
    ? _items[0]
    : _items;
};
const getAmount = (num = 100) => Math.floor(Math.random() * Math.floor(num )) + num;

const axiosWork = ({ url, method = 'get', data = {}, isLoading = true }) => {
  return new Promise((resolve, reject) => {
    if (isLoading) store.isLoading = true;
    window.axios[method](url, data)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      })
      .finally(() => {
        if (isLoading) store.isLoading = false;
      })
  })
}

const axiosWork2 = ({ url, method = 'get', data = {}, isLoading = true, dummyFunc }) => {
  return new Promise((resolve) => {
    if (isLoading) store.isLoading = true;
    setTimeout(() => {
      resolve(dummyFunc())
      if (isLoading) store.isLoading = false;
    }, 200);
  })
}

export const getRegisterList = () => 
  axiosWork2({ dummyFunc: () => {
    return {
      Items: new Array(10).fill('').map((v,i) => {
        const _department = getRandomOption(departmentList);
        return { id: getAmount(999), index: i + 1, school: schoolObj[getRandomOption(schoolList)], departmentType: _department.value, departmentName: _department.text, tuitionFees: getAmount(65000), insurance: getAmount(250), other: getAmount(1000) };
      }
      ),
      Count: 10
    }
  }})

export const getWhiteOffList = () => 
  axiosWork2({ dummyFunc: () => {
    return {
      Items: new Array(10).fill('').map((v,i) => ({ id: getAmount(999), isChecked: true, officeNo:  getAmount(999999), announceDate: getRandomDate(), school: schoolObj[getRandomOption(schoolList)], year: '109', semester: '-', applicationAmount: 1000000, approveDate:  getRandomDate(), approveNo:  getAmount(9999), approveAmount: getAmount(1000000), settleAmount: getAmount(1000000), settleDate:  getRandomDate() })),
      Count: 10
    }
  }});

// export const getApplicationForFundingList = (semester = 1) =>
//   axiosWork2({ dummyFunc: () => {
//     const putAmount = getAmount();
//     return {
//       Items: new Array(10).fill('').map((v,i) => ({ checkStatus: '完成核定', documentNo: getRandomNo(), documentDate: getRandomDate(), school: getRandomSchool(), year: getRandomYear(), semester: Math.floor(Math.random() * 2) + 1, putAmount: putAmount , approveDate: getRandomDate(), approveAmount: putAmount, approveNo: getRandomNo() })),
//       Count: 10
//     }
//   }});

export const getApplicationForFundingList = (actor, params = '') => axiosWork({ url: `/BudgetApply/List?actor=${actor}${params}` });
export const getSummerVacationApplicationList = () =>
  axiosWork2({ dummyFunc: () => {
    return {
      Items: new Array(10).fill('').map((v,i) => {
        const _status = getRandomOption(statusList);
        const putAmount = _status.value === 1
          ? getAmount(10000)
          : null;
        return { 
          checkStatus: _status.text, 
          statusMessage: _status.message, 
          documentNo: getRandomNo(), 
          documentDate: getRandomDate(), 
          school: getRandomSchool(), 
          year: getRandomYear(), 
          semester: Math.floor(Math.random() * 2) + 1, 
          putAmount: putAmount , 
          approveDate: getRandomDate(), 
          approveAmount: putAmount, approveNo: getRandomNo() };
      }),
      Count: 10
    }
  }});

export const getBudgetReviewList = () =>
  axiosWork2({ dummyFunc: () => {
    return {
      Items: new Array(10).fill('').map((v,i) => {
        const _status = getRandomOption(statusList);
        const putAmount = _status.value === 1
          ? getAmount(10000)
          : null;
        return { status: _status.text, statusMessage: _status.message, documentNo: getRandomNo(), documentDate: getRandomDate(), school: getRandomSchool(), year: getRandomYear(), semester: Math.floor(Math.random() * 2) + 1, putAmount: putAmount , approveDate: getRandomDate(), approveAmount: putAmount, approveNo: getRandomNo() };
      }),
      Count: 10
    }
  }});

export const getBudgetApprovalList = () =>
  axiosWork2({ dummyFunc: () => {
    return {
      Items: new Array(10).fill('').map((v,i) => {
        const _status = getRandomOption(statusList);
        const putAmount = _status.value === 1
          ? getAmount(10000)
          : null;
        return { approval: getRandomOption([false, true]), status: _status.text, statusMessage: _status.message, documentNo: getRandomNo(), documentDate: getRandomDate(), school: getRandomSchool(), year: getRandomYear(), semester: Math.floor(Math.random() * 2) + 1, putAmount: putAmount , approveDate: getRandomDate(), approveAmount: putAmount, approveNo: getRandomNo() };
      }),
      Count: 10
    }
  }});

export const getSchoolList = () => axiosWork({ url: 'School/GetAll', isLoading: false });

export const getStudentDeviceList = (id = 1) =>
  axiosWork2({ dummyFunc: () =>
    ({
      Items: new Array(2).fill('').map((v, i) => {
        const _device = deviceList[Math.floor(Math.random() * deviceList.length)];
        const quantity = Math.floor(Math.random() * 3) + 1;
        return { index: i + 1, deviceName: _device.text, quantity, price: _device.value, total: quantity * _device.value, sourceFunding: '自費', instruction: '--' };
      }),
      Count: 2
    })
  })

export const fetchTreatData = (id) =>
  axiosWork2({ dummyFunc: () => {
    return {
      Items: new Array(2).fill('').map((v, i) => {
        const tempArr = new Array(11).fill('').map(v => getAmount(10000));
        const [miscellaneous, insurance, otherSub, foodSub, changeSub, classSub, bookSub, suitSub, homeTownSub, livingSub, deviceSub] = tempArr;
        const total = tempArr.reduce((a,c) => a + c, 0);
        return { index: i + 1, department: (getRandomOption(departmentList)).text, grade: '-', member: getAmount(50), miscellaneous, insurance, otherSub, foodSub, changeSub, classSub, bookSub, suitSub, homeTownSub, livingSub, deviceSub, total };
      }),
      Count: 2
    }
  }})

export const fetchSummerVacationFormData = (id) =>
  axiosWork2({ dummyFunc: () => {
    return {
      Items: new Array(2).fill('').map((v, i) => {
        const name = getRandomOption(nameList);
        const nationality = getRandomOption(nationList);
        const department = getRandomOption(departmentList).text;
        // const _mojay = getRandomOption(majoyList);
        const tmpItems = getRandomOption(majoyList, 3);
        const major = tmpItems.map(v => v.text);
        const hours = tmpItems.map(v => v.value);
        const costPerCredit = tmpItems.map(v => v.price);
        const grades = new Array(tmpItems.length).fill('').map(v => getAmount(50));
        const subtotal = costPerCredit.reduce((sum, price) => sum + price, 0);
        const actualPayment = Math.floor(subtotal * 1.2);
        return { index: i + 1, operations: { edit: 'form', delete: true }, name, nationality, department, major, hours, grades, costPerCredit, subtotal, actualPayment, total: actualPayment, remarks: ''};
      }),
      Count: 2
    }
  }})

export const fetchCheckPublicFeeInfo = () =>
  axiosWork2({ dummyFunc: () => {
    return {
      Items: new Array(2).fill('').map((v, i) => {
        const name = getRandomOption(nameList);
        const department = getRandomOption(departmentList).text;
        const grade = getRandomOption(['一', '二', '三', '四']);
        return { index: i + 1, name, department, grade, entrYear: 110, gradYear: '112', status: '在學中', current: '在學中'};
      }),
      Count: 2
    }
  }})

export const getStudentNameList = () => nameList

export const getDeviceList = ({ schoolcode, byear, term, category }) => axiosWork({ url: `/Budgeting/GetDeviceList?schoolcode=${schoolcode}&byear=${byear}&term=${term}&category=${category}` })

export const putDevice = data => axiosWork({ url: '/Budgeting/SetDevices', method: 'put', data });
