<template>
  <div
    class="border rounded px-2 py-1 d-flex justify-content-between align-items-center position-relative bg-white"
  >
    <input
      :value="
        chYears.includes('Invalid') || chYears.includes('-191') ? '' : chYears
      "
      class="no-border flex-1"
      @click.stop="showCalendar = !showCalendar"
      style="width:100px"
    />
    <div class="calendar-icon" @click.stop="showCalendar = !showCalendar" />

    <div
      ref="calendar"
      class="position-absolute border rounded calendar-table"
      style="width: 300px; min-height: 200px; left: 0; top: calc(100% + 5px);z-index:99"
      v-show="showCalendar"
      v-closable="{
        exclude: ['calendar'],
        handler: 'onClose',
      }"
    >
      <div class="d-flex justify-content-between bg-white">
        <b-button @click.stop="changeMonth(false, null)" class="border"
          >prev</b-button
        >
        {{ chYearMonth }}
        <b-button @click.stop="changeMonth(true, null)" class="border"
          >next</b-button
        >
      </div>
      <div class="d-grid grid-col-7 inner">
        <div
          v-for="weekDay in ['日', '一', '二', '三', '四', '五', '六']"
          v-text="weekDay"
          class="week"
          :key="weekDay"
        />
        <div
          class="outCalendar"
          v-for="pday in calendarData.prevNumber"
          :key="calendarData.calendarKey + 'prev' + pday"
          @click.stop="changeMonth(false, pday)"
        >
          {{ pday }}
        </div>
        <div
          v-for="day in calendarData.dayNumber"
          :key="calendarData.calendarKey + 'cur' + day"
          :class="{
            'is-today': checkIsToday(day),
            'is-chosen': checkIsChosen(day),
          }"
          @click.stop="changeCurrentDay(day)"
        >
          {{ day }}
        </div>
        <div
          class="outCalendar"
          v-for="nday in calendarData.nextNumber"
          :key="calendarData.calendarKey + 'next' + nday"
          @click.stop="changeMonth(true, nday)"
        >
          {{ nday }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [Date, Object, String],
      required: true,
    },
    nowDataIndex: {
      type: String,
      required: false,
    },
  },
  props: ["value", "nowDataIndex"],
  data() {
    return {
      currentTime: null,
      calendarDate: null,
      showCalendar: false,
    };
  },
  computed: {
    chYears: {
      set(value) {
        this.currentTime = value;
      },
      get() {
        return this.convertChYear(this.currentTime);
      },
    },
    chYearMonth() {
      return this.convertChYear(this.calendarDate).slice(0, 6);
    },
    calendarData() {
      const _moment = this.$moment(this.calendarDate);
      const firstWeekDay = _moment.startOf("month").day() + 1;
      const preMonthDays = _moment.add(-1, "months").daysInMonth();
      const prevNumber = new Array(firstWeekDay - 1)
        .fill("")
        .map((v, i) => preMonthDays - i)
        .reverse();
      const dayNumber = this.$moment(this.calendarDate).daysInMonth();
      return {
        calendarKey: _moment.format("YYYY-MM"),
        prevNumber,
        nextNumber: new Array((7 - ((firstWeekDay - 1 + dayNumber) % 7)) % 7)
          .fill("")
          .map((v, i) => i + 1),
        dayNumber,
        firstWeekDay,
      };
    },
  },
  created() {
    this.currentTime = this.$moment(this.value);
    this.calendarDate = this.$moment();
  },
  methods: {
    onClose() {
      this.showCalendar = false;
    },
    checkIsChosen(day) {
      return (
        this.$moment(this.currentTime).format("YYYY-MM-DD") ===
        this.$moment(this.calendarDate).format("YYYY-MM-") +
          ("00" + day).slice(-2)
      );
    },
    checkIsToday(day) {
      return (
        this.$moment().format("YYYY-MM-DD") ===
        this.$moment(this.calendarDate).format("YYYY-MM-") +
          ("" + day).slice(-2)
      );
    },
    convertChYear(value) {
      return `${parseInt(this.$moment(value).format("YYYY")) -
        1911}-${this.$moment(value).format("MM-DD")}`;
    },
    changeMonth(isNext, day) {
      this.calendarDate = this.$moment(
        this.$moment(this.calendarDate)
          .add(isNext ? 1 : -1, "months")
          .format("YYYY-MM-01")
      );

      if (day) {
        this.currentTime = this.$moment(
          this.$moment(this.calendarDate).format("YYYY-MM-") + day
        );
        this.showCalendar = false;
      }
    },
    changeCurrentDay(day) {
      this.currentTime = new Date(
        this.$moment(this.calendarDate).format("YYYY-MM-") + day
      );
      const obj = {
        index: this.nowDataIndex,
        data: this.currentTime,
      };
      this.$emit("update2", obj);
      this.$emit("input", this.currentTime);
      this.showCalendar = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.calendar-icon {
  background: transparent
    url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABmJLR0QA/wD/AP+gvaeTAAAAYElEQVQ4jWNgoDL4T6o8E5RuY2Bg+ImkCBdmgKprQTfoJwMDgxCRLhBiYGD4QarTcapnocAQ6riAiYACXDTtXEDI5oFzAUEAS0jEAKwJqQVqCL5kDMM/GRgYmol1Ge0BAEXwMwOkmd0/AAAAAElFTkSuQmCC")
    no-repeat center center;
  width: 16px;
  height: 16px;
}
.no-border {
  border: 0;
}
.print-page {
  height: 100vh;
}
.calendar-table {
  background-color: #eee;
  .inner {
    grid-gap: 1px;
    > div {
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #fff;
      cursor: pointer;
      padding: 5px;
      &:hover {
        box-shadow: 0 0 0 1px var(--warning) inset;
      }
      &.is-today {
        background-color: rgb(222, 221, 255);
      }
      &.is-chosen {
        box-shadow: 0 0 0 3px var(--primary) inset;
      }
      &.week {
        background-color: var(--dark);
        color: #fff;
        &:hover {
          box-shadow: unset;
        }
      }
      &.outCalendar {
        background-color: var(--secondary);
      }
    }
  }
}
</style>
